/**
 * A nav item consists of the shown text id "intl languages json files" that be stored in the first place of the array & link that be stored in the seconde place.
 * Home page data must be stored in the first place of NAV_ITEMS array.
 */

export default [
  ['Nav.home', '/'],
  ['Nav.webshop', '/webshop'],
  ['Nav.aboutUs', '/about-us'],
  ['Nav.contact', '/contact'],
  ['Nav.training', '/training'],
  ['Nav.news', '/news'],
];
