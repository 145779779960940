import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { string, func, shape } from 'prop-types';
import DefaultButton from '../Buttons/DefaultButton';
import COLORS from '../../constants/colors';
import { injectIntl } from 'gatsby-plugin-intl';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      height: '100%',
      minWidth: '75vw',
      maxWidth: '75vw',
    },
  },
  object: {
    height: '100%',
    overflowY: 'hidden',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BLACK,
    display: 'flex',
  },
  download: { margin: 'auto', width: 150, height: 50 },
});

const PdfViewer = ({ intl, url, triggerElement }) => {
  const { formatMessage } = intl;
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  const handleOpen = () => {
    isSupported() && setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const directNavigation = () => {
    window.open(url, '_blank', 'noreferrer,noopener');
  };

  const isSupported = () => {
    const result = false;
    const mimeTypes = navigator.mimeTypes;
    for (let i = 0; i < mimeTypes.length; i++) {
      if (mimeTypes[i].type === 'application/pdf') {
        return true;
      }
    }
    directNavigation();
    return result;
  };

  return (
    <>
      {triggerElement({ onClick: handleOpen })}
      {
        <Dialog
          className={classes.root}
          open={isVisible}
          onClose={handleClose}
        >
          <object
            className={classes.object}
            data={url}
            type="application/pdf"
          >
            <div className={classes.container}>
              <DefaultButton
                onClick={directNavigation}
                className={classes.download}
              >
                {formatMessage({ id: 'PdfViewer.button' })}
              </DefaultButton>
            </div>
          </object>
        </Dialog>
      }
    </>
  );
};

PdfViewer.propTypes = {
  url: string.isRequired,
  triggerElement: func.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

export default injectIntl(PdfViewer);
