import React /*, { useEffect, useState }*/ from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid /*, Typography */ } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import Logo from '../images/headzUp-whiteSmall.png';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { shape, func } from 'prop-types';
import { injectIntl, Link } from 'gatsby-plugin-intl';
import NAV_ITEMS from '../constants/nav-items';
//import DefaultButton from './Buttons/DefaultButton';
//import TextInput from './Inputs/TextInput';
//import { validateEmail } from '../utils/validations';

import {
  SUPPORT_EMAIL,
  ADDRESS,
  POSTAL_CODE_CITY,
  POSTAL_CODE_CITY_COUNTRY,
  COUNTRY,
  YOUTUBE_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
  ORG_NUM,
} from '../constants/company-info';
import PdfViewer from './Modals/PdfViewer';
import MANUAL from '../assets/documents/HeadzUP-manual-A4-min.pdf';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: COLORS.DARK_BLUE,
    color: `${COLORS.WHITE} !important`,
    fontSize: FONTS.SIZE.S,
    padding: '75px 75px 30px 75px',
    paddingRight: 25,
    '& a,p': { 'line-height': 1.4 },
    '& a': {
      width: 'fit-content',
      textDecoration: 'none',
      display: 'inline-block',
      '&:hover': {
        color: `${COLORS.RED}`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      paddingTop: 50,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XS,
    },
  },
  column: {
    lineHeight: 1.2,
    margin: '75px 0',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '40px 0 0 0',
    },
  },
  subtitle: {
    fontWeight: FONTS.WEIGHT.BOLD,
    fontSize: FONTS.SIZE.M,
    marginBottom: 5,
  },
  logo: {
    width: 250,
    [theme.breakpoints.down('sm')]: { width: '100%' },
    [theme.breakpoints.down('xs')]: {
      width: 250,
      paddingBottom: 50,
    },
  },
  emailInput: { minHeight: 65 },
  pagesColumn: {
    '& >*': {
      textTransform: 'uppercase',
      margin: '5px 0',
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 25,
    '& >*': {
      lineHeight: '0 !important',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: { marginRight: 5 },
      [theme.breakpoints.down('xs')]: { marginRight: 25 },
    },
    '& svg': {
      fontSize: 32,
      [theme.breakpoints.down(820)]: { fontSize: 30 },
      [theme.breakpoints.down('xs')]: { fontSize: 50 },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  scaledUpIcon: {
    '&>path': {
      transform: 'scale(1.15,1.3) translate(-5%, -12%)',
    },
  },
  copyRights: {
    margin: '0',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontSize: FONTS.SIZE.XS,
  },
  registerButton: { height: 44 },
  buttonAsLink: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    width: 'fit-content',
    cursor: 'pointer',
    color: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      color: COLORS.RED,
    },
  },
}));

const Footer = ({ intl }) => {
  const { formatMessage } = intl;
  const classes = useStyles();

  /*
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    setIsValidEmail(true);
  }, [email]);
  const handleRegister = () => {
    const emailIsValid = validateEmail(email);
    //TODO: Activate register function
    emailIsValid && console.log(email);
    !emailIsValid && setIsValidEmail(emailIsValid);
  };
*/
  /*TODO: change static texts*/
  return (
    <Grid container className={classes.root}>
      <Grid xs={12} sm={4} item>
        <Link to={NAV_ITEMS[0][1]}>
          <img
            className={classes.logo}
            src={Logo}
            alt="logo"
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        {/*TODO:The following code could be activated then.
        <Typography
          variant="subtitle1"
          className={classes.title}
        >
          {formatMessage({
            id: 'Footer.subscribeSection.subtitle1',
          })}
        </Typography>
        <Grid item md={8}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextInput
                label={formatMessage({
                  id:
                    'Footer.subscribeSection.textInputLabel1',
                })}
                error={!isValidEmail}
                helperText={
                  !isValidEmail && 'Ogiltigt e-post'
                }
                onChange={e => {
                  setEmail(e.target.value);
                }}
                onBlur={() => {
                  setIsValidEmail(true);
                }}
                className={classes.emailInput}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultButton
                className={classes.registerButton}
                onClick={handleRegister}
              >
                {formatMessage({
                  id: 'Footer.subscribeSection.button1',
                })}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>
              */}
      </Grid>
      <Grid
        xs={12}
        sm={2}
        item
        className={classes.iconsContainer}
      >
        <a
          href={YOUTUBE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon className={classes.scaledUpIcon} />
        </a>
        <a
          href={FACEBOOK_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href={INSTAGRAM_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href={TWITTER_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon />
        </a>
      </Grid>
      <Grid className={classes.column} xs={12} sm={4} item>
        {/*TODO:Change links destination */}
        <p className={classes.subtitle}>
          {formatMessage({ id: 'Footer.subtitle1' })}
        </p>
        {/*<Link to="">
          {formatMessage({ id: 'Footer.FAQ' })}
            </Link>*/}
        <PdfViewer
          url={MANUAL}
          triggerElement={props => (
            <button
              {...props}
              className={classes.buttonAsLink}
            >
              {formatMessage({ id: 'Footer.manual' })}
            </button>
          )}
        />
        <Link to="/cookie-policy">
          {formatMessage({ id: 'Footer.cookiePolicy' })}
        </Link>
        <Link to="/purchase-policy">
          {formatMessage({ id: 'Footer.purchasePolicy' })}
        </Link>
        {/*<Link to="">
          {formatMessage({ id: 'Footer.trackYourOrder' })}
        </Link>
        <Link to="">
          {formatMessage({
            id: 'Footer.returnAndRepurchase',
          })}
        </Link>*/}
      </Grid>
      <Grid xs={12} sm={6} item className={classes.column}>
        {/*TODO:Change address Link*/}
        <a
          href={`https://www.google.se/maps/search/${ADDRESS},${POSTAL_CODE_CITY_COUNTRY}`}
          target="_blank"
          rel="noreferrer"
        >
          <p>{ADDRESS}</p>
          <p>{POSTAL_CODE_CITY}</p>
          <p>{COUNTRY}</p>
        </a>
        <br />
        <a href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </a>
        <p>Org. nr. {ORG_NUM}</p>
      </Grid>
      <Grid
        xs={12}
        sm={2}
        item
        className={`${classes.column} ${classes.pagesColumn}`}
      >
        {NAV_ITEMS.map(item => (
          <Link key={item[1]} to={item[1]}>
            {formatMessage({ id: item[0] })}
          </Link>
        ))}
      </Grid>
      <Grid
        xs={12}
        lg={12}
        item
        className={`${classes.column} ${classes.copyRights}`}
      >
        &copy; Headzup Sport AB
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

export default injectIntl(Footer);
