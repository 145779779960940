import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Drawer,
  IconButton,
  Divider,
  Toolbar,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { injectIntl, navigate } from 'gatsby-plugin-intl';
import { shape, func } from 'prop-types';
import NAV_ITEMS from '../constants/nav-items';
import Logo from '../images/Logo/headz-up-logo-w.png';

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 80,
      width: '100%',
      borderBottom: `2px solid ${COLORS.RED}`,
    },
  },
}))(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div /> }}
  />
));

const useStyles = makeStyles(theme => ({
  navBar: {
    paddingLeft: '30px',
    paddingTop: '30px',
    position: 'absolute',
  },
  appBar: {
    position: 'relative',
    margin: '0',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&>.MuiTabs-root': {
      minHeight: 'auto',
    },
  },
  logoTab: {
    opacity: 1,
    marginRight: 20,
    paddingBottom: 0,
    '& .MuiTouchRipple-root': {
      color: COLORS.RED,
      zIndex: -1,
    },
  },
  appBarSmallDevice: {
    backgroundColor: 'rgba(20, 20, 20 , 0.2)',
    boxShadow: 'none',
    backdropFilter: 'blur(1px)',
    [theme.breakpoints.down('md')]: {
      height: 46,
    },
  },
  tab: {
    minWidth: 'auto',
    padding: '0',
    margin: '0 12px',
    minHeight: 'auto',
    color: COLORS.WHITE,
    fontSize: FONTS.SIZE.XXS,
    opacity: 1,

    '& .MuiTouchRipple-root': {
      color: COLORS.RED,
      zIndex: -1,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XXS,
      margin: '0 5px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXS,
    },
  },
  openButton: {
    color: 'inherit',
    marginLeft: 'auto',
  },
  closeButton: {
    display: 'block',
    marginRight: 'auto',
    color: 'inherit',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '50vw',
      backgroundColor: 'rgba(30, 40, 50 , 0.9)',
      color: COLORS.WHITE,
      backdropFilter: 'blur(10px)',
    },
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: COLORS.RED,
    },
  },
  drawerLogoTab: {
    opacity: 1,
  },
  logo: {
    width: '136px',
  },
}));

const getShownPageIndex = currentPath => {
  for (let index = 1; index < NAV_ITEMS.length; index++) {
    if (currentPath.includes(NAV_ITEMS[index][1])) {
      return index;
    }
  }
  return false;
};

const Nav = ({ intl }) => {
  const { formatMessage } = intl;
  const smallDevice = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);
  const currentPath = useLocation().pathname;
  const [selectedPage, setSelectedPage] = useState(
    getShownPageIndex(currentPath)
  );
  const classes = useStyles();

  useEffect(() => {
    setOpen(false);
  }, [smallDevice]);

  const handleChange = (e, index) => {
    const delay = index === 0 ? 0 : 300;
    index === 0
      ? setSelectedPage(false)
      : setSelectedPage(index);
    setTimeout(() => navigate(NAV_ITEMS[index][1]), delay);
  };

  return (
    <>
      {!smallDevice && (
        <div className={classes.navBar}>
          <AppBar
            color="default"
            className={classes.appBar}
          >
            <StyledTabs
              value={selectedPage}
              onChange={handleChange}
              className={classes.tabs}
            >
              {NAV_ITEMS.map((item, i) =>
                i === 0 ? (
                  <Tab
                    key={formatMessage({ id: item[0] })}
                    className={classes.logoTab}
                    label={
                      <img
                        src={Logo}
                        className={classes.logo}
                        alt="Headz Up Sport Ab"
                      ></img>
                    }
                  />
                ) : (
                  <Tab
                    key={formatMessage({ id: item[0] })}
                    label={formatMessage({ id: item[0] })}
                    className={classes.tab}
                  />
                )
              )}
            </StyledTabs>
          </AppBar>
        </div>
      )}
      {smallDevice && (
        <div>
          <AppBar className={classes.appBarSmallDevice}>
            <Toolbar>
              <Button
                className={classes.drawerLogoTab}
                onClick={() => navigate(NAV_ITEMS[0][1])}
              >
                <img
                  src={Logo}
                  className={classes.logo}
                  alt="Headz Up Sport Ab"
                />
              </Button>
              {!open && (
                <IconButton
                  className={classes.openButton}
                  onClick={() => setOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="right"
            open={open}
            className={classes.drawer}
          >
            <div>
              <IconButton
                className={classes.closeButton}
                onClick={() => setOpen(false)}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <Tabs
              orientation="vertical"
              value={selectedPage}
              onChange={handleChange}
              className={classes.tabs}
            >
              {NAV_ITEMS.map((item, i) =>
                i === 0 ? (
                  <Tab
                    key={formatMessage({ id: item[0] })}
                    className={classes.drawerLogoTab}
                    label={
                      <img
                        src={Logo}
                        className={classes.logo}
                        alt="Headz Up Sport Ab"
                      ></img>
                    }
                  />
                ) : (
                  <Tab
                    key={formatMessage({ id: item[0] })}
                    label={formatMessage({ id: item[0] })}
                  />
                )
              )}
            </Tabs>
          </Drawer>
        </div>
      )}
    </>
  );
};

Nav.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};

export default injectIntl(Nav);
