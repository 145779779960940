import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { string } from 'prop-types';
import COLORS from '../../constants/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
    borderRadius: 0,
    width: '100%',
    padding: '10px 8px',
    '&:hover': {
      backgroundColor: COLORS.RED,
      filter: ' brightness(0.95)',
    },
  },
});

const DefaultButton = (props, to) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      to={to}
      className={`${classes.root} ${props.className}`}
    />
  );
};

DefaultButton.propTypes = {
  className: string,
};

DefaultButton.defaultProps = {
  className: '',
};

export default DefaultButton;
