export const ORG_NUM = '559223-6144';
export const SUPPORT_EMAIL = 'info@headzupsport.com';
export const ADDRESS = 'Ruskvädersgatan 8';
export const POSTAL_CODE_CITY = '418 34 Göteborg';
export const POSTAL_CODE_CITY_COUNTRY =
  '418 34 Göteborg Sverige';
export const COUNTRY = 'Sverige';
export const YOUTUBE_LINK =
  'https://www.youtube.com/channel/UC3zCjwUUr88R7krQOXIrhzQ';
export const FACEBOOK_LINK =
  'https://www.facebook.com/Headzup-Sport-650399452240345';
export const INSTAGRAM_LINK =
  'https://www.instagram.com/headzupsport/';
export const TWITTER_LINK = 'https://twitter.com/HeadzupA';
export const TELEPHONE = '+46 70 308 78 76';
export const PRICE = 749;
