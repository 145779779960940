export default {
  WHITE: '#ffffff',
  BLACK: '#141414',
  SILVER: '#CBCBCB',
  BLUE: '#2F3A45',
  DARK_BLUE: '#1E2832',
  LIGHT_RED: '#FF0000',
  RED: '#CD2027',
  LIGHT_GREY: '#6E747A',
  GREY: '#6E6D6D',
};
