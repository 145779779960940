import React from 'react';
import './base.css';
import Nav from './nav';
import CookieConsent from '../components/CookieConsent';
import Footer from './Footer-v2';
import { element, oneOfType, arrayOf } from 'prop-types';

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
      <CookieConsent />
    </>
  );
};

Layout.propTypes = {
  children: oneOfType([element, arrayOf(element)])
    .isRequired,
};

export default Layout;
