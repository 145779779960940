const SIZE = {
  XXXS: 10,
  XXSS: 12,
  XXS: 14,
  XS: 16,
  S: 18,
  M: 20,
  XM: 24,
  XXM: 30,
  L: 40,
  XL: 50,
  XXL: 60,
  XXXL: 70,
};

const FAMILY = {
  PRIMARY: 'IBMPlexSans',
  SECONDARY: 'Running',
};

const WEIGHT = {
  LIGHT: 'lighter',
  NORMAL: 'normal',
  BOLD: 'bold',
};

export default {
  SIZE,
  FAMILY,
  WEIGHT,
};
